<template>
  <div class="pack-add">
    <div class="field has-addons">
      <button
        class="button mr-2 is-primary is-light"
        v-on:click="onAddClick()"
      >
        Add new pack
      </button>
    </div>
  </div>
  <div class="modal" :class="{ 'is-active': showForm }">
    <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box">
          <form @submit.prevent="handleSubmit">
            <div class="field">
              <label class="label">Name</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  ref="packNameRef"
                  placeholder="E.g. Beginner (A0), or Business English (Advanced)"
                  v-model="name"
                  :disabled="isSaving"
                />
                <p v-if="nameError" class="help is-danger">{{ nameError }}</p>
              </div>
            </div>
            <div class="field">
              <label class="label">Price</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="E.g. 5000"
                  v-model="currentPrice"
                  :disabled="isSaving"
                />
                <p v-if="currentPriceError" class="help is-danger">{{ currentPriceError }}</p>
              </div>
            </div>
            <div class="field">
              <label class="label">Category</label>
              <progress v-if="categoriesLoading" class="progress is-small is-info" max="100">
              </progress>
              <div show="!categoriesLoading" class="select">
                <select
                  class="input"
                  placeholder="E.g. 5000"
                  v-model="categoryId"
                  :disabled="isSaving"
                >
                  <option
                    v-for="category in categories"
                    v-bind:key="category.id"
                    v-bind:value="category.id"
                  >
                    {{ category.name }}
                  </option>
                </select>
              </div>
              <p v-if="categoryError" class="help is-danger">{{ categoryError }}</p>
            </div>
            <div class="field">
              <label class="label">Short Description</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="
E.g. This deck is about basic grammar. Based on Dohar Peter's Kis angol nyelvtan."
                  v-model="shortDescription"
                  :disabled="isSaving"
                />
              </div>
            </div>
            <div class="field">
              <label class="label">Long Description</label>
              <div class="control">
                <textarea
                  class="textarea"
                  type="text"
                  placeholder="Further info and example sentences should be here"
                  v-model="longDescription"
                  :disabled="isSaving"
                ></textarea>
              </div>
            </div>
            <div class="field">
              <label class="checkbox">
                <input
                  class="checkbox"
                  type="checkbox"
                  v-model="published"
                  :disabled="isSaving"
                />
                Published
              </label>
            </div>
            <div class="field">
              <label class="checkbox">
                <input
                  class="checkbox"
                  type="checkbox"
                  v-model="public"
                  :disabled="isSaving"
                />
                Public
              </label>
            </div>
            <div class="field is-grouped">
              <div class="control">
                <button
                  type="submit"
                  class="button is-primary"
                  v-bind:class="isSaving ? 'is-loading' : ''"
                >
                  Add
                </button>
              </div>
              <div class="control">
                <button
                  type="button"
                  class="button"
                  v-if="!isSaving"
                  v-on:click="onCancelClick()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { packService } from '@/services/pack.service';
import { Category } from '@/api-domain/category';
import { categoryService } from '@/services/category.service';

@Options({
  emits: ['created'],
})
export default class EzAddPack extends Vue {
  categories: Category[] = [];

  categoriesLoading = false;

  name = '';

  currentPrice = '';

  categoryId = '';

  showForm = false;

  isSaving = false;

  nameError = '';

  currentPriceError = '';

  categoryError = '';

  published = false;

  public = true;

  shortDescription = '';

  longDescription = '';

  async fetchCategories(): Promise<void> {
    this.categoriesLoading = true;

    try {
      this.categories = await categoryService.getAll();
      this.categoriesLoading = false;
    } catch {
      this.categoriesLoading = false;
      // TODO: handle error
    }
  }

  created(): void {
    this.fetchCategories();
  }

  onAddClick(): void {
    this.showForm = true;
    setTimeout(() => {
      (this.$refs.packNameRef as HTMLInputElement).focus();
    }, 0);
  }

  onCancelClick(): void {
    this.showForm = false;
  }

  validateName(): void {
    this.nameError = !this.name.trim() ? 'Name should be filled' : '';
  }

  validateCategory(): void {
    this.categoryError = !this.categoryId ? 'Category should be selected' : '';
  }

  validateCurrentPrice(): void {
    this.currentPriceError = !this.currentPrice.trim() || !/^\d*(\.\d{0,2})?$/.test(this.currentPrice.trim())
      ? 'Price should be a number'
      : '';
  }

  clearData(): void {
    this.name = '';
    this.currentPrice = '';
  }

  async handleSubmit(): Promise<void> {
    this.validateName();
    this.validateCurrentPrice();
    this.validateCategory();

    if (this.nameError || this.currentPriceError || this.categoryError) {
      return;
    }
    this.isSaving = true;
    const newPack = {
      name: this.name,
      currentPrice: this.currentPrice,
      shortDescription: this.shortDescription,
      longDescription: this.longDescription,
      published: this.published,
      categoryId: this.categoryId,
      public: this.public,
    };
    try {
      const createdPack = await packService.create(newPack);
      this.$emit('created', createdPack);
      this.clearData();
      this.isSaving = false;
      this.showForm = false;
    } catch {
      this.isSaving = false;
    }
  }
}
</script>
