
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { packService } from '@/services/pack.service';
import { Pack } from '@/api-domain/pack';
import { categoryService } from '@/services/category.service';
import { Category } from '@/api-domain/category';

@Options({
  emits: ['updated'],
})
export default class EzEditPack extends Vue {
  @Prop({ isRequired: true }) pack!: Pack;

  categories: Category[] = [];

  categoriesLoading = false;

  name = '';

  currentPrice = '';

  categoryId = '';

  showForm = false;

  isSaving = false;

  nameError = '';

  currentPriceError = '';

  published = false;

  public = false;

  shortDescription = '';

  longDescription = '';

  async fetchCategories(): Promise<void> {
    this.categoriesLoading = true;

    try {
      this.categories = await categoryService.getAll();
      this.categoriesLoading = false;
    } catch {
      this.categoriesLoading = false;
      // TODO: handle error
    }
  }

  created(): void {
    this.fetchCategories();
  }

  onEditClick(): void {
    this.name = this.pack.name;
    this.currentPrice = this.pack.currentPrice;
    this.shortDescription = this.pack.shortDescription;
    this.longDescription = this.pack.longDescription;
    this.published = this.pack.published;
    this.public = this.pack.public;
    this.categoryId = this.pack.categoryId || '';

    this.showForm = true;

    setTimeout(() => {
      (this.$refs.packNameRef as HTMLInputElement).focus();
    }, 0);
  }

  onCancelClick(): void {
    this.showForm = false;
  }

  validateName(): void {
    this.nameError = !this.name.trim() ? 'Name should be filled' : '';
  }

  validateCurrentPrice(): void {
    this.currentPriceError = !/^\d*(\.\d{0,2})?$/.test(this.currentPrice.trim())
      ? 'Price should be a number'
      : '';
  }

  async handleSubmit(): Promise<void> {
    if (!this.pack.id) {
      throw new Error('Missing id property');
    }

    this.validateName();

    this.validateCurrentPrice();

    if (this.nameError || this.currentPriceError) {
      return;
    }
    this.isSaving = true;
    const newPack = {
      name: this.name,
      currentPrice: this.currentPrice,
      shortDescription: this.shortDescription,
      longDescription: this.longDescription,
      published: this.published,
      public: this.public,
    } as Pack;

    if (this.categoryId) {
      newPack.categoryId = this.categoryId;
    }

    try {
      const createdPack = await packService.update(this.pack.id, newPack);
      this.$emit('updated', createdPack);
      this.isSaving = false;
      this.showForm = false;
    } catch {
      this.isSaving = false;
    }
  }
}
