import { cardService } from './card.service';

export class ExportService {
  async getExportContent(packId: string): Promise<string> {
    const cards = await cardService.getAll(packId);
    const lines = cards.map((card) => `${card.back}\t${card.front}\t${card.info}`);
    return lines.join('\r\n');
  }
}

export const exportService = new ExportService();
