
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { PackWithCount } from '@/api-domain/packWithCount';

@Options({
  components: {
  },
})
export default class EzPack extends Vue {
  @Prop({ required: true }) pack!: PackWithCount;

  @Prop({ required: false }) isSelected = false;
}
