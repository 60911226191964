
import { Options, Vue } from 'vue-class-component';
import { packService } from '@/services/pack.service';
import { Category } from '@/api-domain/category';
import { categoryService } from '@/services/category.service';

@Options({
  emits: ['created'],
})
export default class EzAddPack extends Vue {
  categories: Category[] = [];

  categoriesLoading = false;

  name = '';

  currentPrice = '';

  categoryId = '';

  showForm = false;

  isSaving = false;

  nameError = '';

  currentPriceError = '';

  categoryError = '';

  published = false;

  public = true;

  shortDescription = '';

  longDescription = '';

  async fetchCategories(): Promise<void> {
    this.categoriesLoading = true;

    try {
      this.categories = await categoryService.getAll();
      this.categoriesLoading = false;
    } catch {
      this.categoriesLoading = false;
      // TODO: handle error
    }
  }

  created(): void {
    this.fetchCategories();
  }

  onAddClick(): void {
    this.showForm = true;
    setTimeout(() => {
      (this.$refs.packNameRef as HTMLInputElement).focus();
    }, 0);
  }

  onCancelClick(): void {
    this.showForm = false;
  }

  validateName(): void {
    this.nameError = !this.name.trim() ? 'Name should be filled' : '';
  }

  validateCategory(): void {
    this.categoryError = !this.categoryId ? 'Category should be selected' : '';
  }

  validateCurrentPrice(): void {
    this.currentPriceError = !this.currentPrice.trim() || !/^\d*(\.\d{0,2})?$/.test(this.currentPrice.trim())
      ? 'Price should be a number'
      : '';
  }

  clearData(): void {
    this.name = '';
    this.currentPrice = '';
  }

  async handleSubmit(): Promise<void> {
    this.validateName();
    this.validateCurrentPrice();
    this.validateCategory();

    if (this.nameError || this.currentPriceError || this.categoryError) {
      return;
    }
    this.isSaving = true;
    const newPack = {
      name: this.name,
      currentPrice: this.currentPrice,
      shortDescription: this.shortDescription,
      longDescription: this.longDescription,
      published: this.published,
      categoryId: this.categoryId,
      public: this.public,
    };
    try {
      const createdPack = await packService.create(newPack);
      this.$emit('created', createdPack);
      this.clearData();
      this.isSaving = false;
      this.showForm = false;
    } catch {
      this.isSaving = false;
    }
  }
}
