
import { Options, Vue } from 'vue-class-component';
import EzPack from '@/components/packs/EzPack.vue';
import EzAddPack from '@/components/packs/EzAddPack.vue';
import EzEditPack from '@/components/packs/EzEditPack.vue';
import EzDeletePack from '@/components/packs/EzDeletePack.vue';
import { Pack } from '@/api-domain/pack';
import { packService } from '@/services/pack.service';
import { exportService } from '@/services/export.service';

@Options({
  components: {
    EzAddPack,
    EzEditPack,
    EzDeletePack,
    EzPack,
  },
})
export default class Packs extends Vue {
  href = '';

  packs: Pack[] = [];

  selectedPack: Pack | null = null;

  fetchError = false;

  isFetched = false;

  async fetchPacks(): Promise<void> {
    try {
      this.packs = await packService.getAll();
      this.isFetched = true;
    } catch {
      this.fetchError = true;
      // TODO: how to handle? polling in every 10 seconds?
      // try again button?
    }
  }

  async created(): Promise<void> {
    await this.fetchPacks();
  }

  async onPackCreated(): Promise<void> {
    await this.fetchPacks();
  }

  async onPackUpdated(): Promise<void> {
    this.selectedPack = null;
    // Should be fetched, because the ordering logic is on the server side
    await this.fetchPacks();
  }

  async onPackDeleted(): Promise<void> {
    this.selectedPack = null;
    await this.fetchPacks();
  }

  onPackClick(pack: Pack): void {
    if (this.selectedPack === pack) {
      this.selectedPack = null;
    } else {
      this.selectedPack = pack;
    }
  }

  onEditCardsClick(): void {
    if (!this.selectedPack) {
      return;
    }
    this.$router.push(`/packs/${this.selectedPack.id}/cards`);
  }

  onImportCardsClick(): void {
    if (!this.selectedPack) {
      return;
    }
    this.$router.push(`/packs/${this.selectedPack.id}/import`);
  }

  async onExportCardsClick(): Promise<void> {
    if (!this.selectedPack || !this.selectedPack.id) {
      return;
    }
    const content = await exportService.getExportContent(this.selectedPack.id);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([content], { type: 'text/tab-separated-values' }));
    link.download = `${this.selectedPack.name}.tsv`;
    link.click();
  }
}
