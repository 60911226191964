
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { packService } from '@/services/pack.service';
import { Pack } from '@/api-domain/pack';

@Options({
  emits: ['deleted'],
})
export default class EzDeletePack extends Vue {
  @Prop({ isRequired: true }) pack!: Pack;

  showForm = false;

  isSaving = false;

  onDeleteClick(): void {
    this.showForm = true;
  }

  onCancelClick(): void {
    this.showForm = false;
  }

  async onSubmit(): Promise<void> {
    if (!this.pack.id) {
      throw new Error('Missing id property');
    }

    this.isSaving = true;
    try {
      await packService.delete(this.pack.id);
      this.$emit('deleted', this.pack.id);
      this.isSaving = false;
      this.showForm = false;
    } catch {
      this.isSaving = false;
    }
  }
}
