<template>
  <div class="card is-clickable" :class="{ 'has-background-primary': isSelected }">
    <div class="card-content">
      <div class="content">
        <h4>{{ pack.name }}</h4>
        <p>
          Price: <strong>{{ pack.currentPrice }}</strong><br>
          Cards count: <strong>{{ pack._count.cards }}</strong>
        </p>
        <div class="ezpack__tags">
          <span v-if="pack.public" class="tag is-light mr-2">Public</span>
          <span v-if="!pack.public" class="tag is-dark mr-2">Private</span>
          <span v-if="pack.published" class="tag is-success">Published</span>
          <span v-if="!pack.published" class="tag is-warning">Unpublished</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { PackWithCount } from '@/api-domain/packWithCount';

@Options({
  components: {
  },
})
export default class EzPack extends Vue {
  @Prop({ required: true }) pack!: PackWithCount;

  @Prop({ required: false }) isSelected = false;
}
</script>
<style scoped lang="scss">
.ezpack__tags {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

</style>
