import authHeader from '@/helpers/auth-header';
import { Card } from '@/api-domain/card';
import http from '@/http-common';

export class CardService {
  async create(packId: string, card: Omit<Card, 'packId'>): Promise<Card> {
    const result = await http.post(`/packs/${packId}/cards`, card, { headers: authHeader() });
    return result.data;
  }

  async createBatch(packId: string, cards: Omit<Card, 'packId'>[]): Promise<Card> {
    const result = await http.post(`/packs/${packId}/cards/batch`, cards, { headers: authHeader() });
    return result.data;
  }

  async updateBatch(packId: string, cards: Omit<Card, 'packId'>[]): Promise<Card> {
    const result = await http.put(`/packs/${packId}/cards/batch`, cards, { headers: authHeader() });
    return result.data;
  }

  async getAll(packId?: string): Promise<Card[]> {
    if (packId) {
      return (await http.get(`/packs/${packId}/cards`, { headers: authHeader() })).data;
    }
    return (await http.get('/cards', { headers: authHeader() })).data;
  }

  async update(id: string, card: Omit<Card, 'packId'>): Promise<void> {
    return http.put(`/cards/${id}`, card, { headers: authHeader() });
  }

  async delete(id: string): Promise<void> {
    return http.delete(`/cards/${id}`, { headers: authHeader() });
  }
}

export const cardService = new CardService();
