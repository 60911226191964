import authHeader from '@/helpers/auth-header';
import { Category } from '@/api-domain/category';
import http from '@/http-common';

type CreateCategoryPayload = Omit<Category, 'order'>;

export class CategoryService {
  async create(category: CreateCategoryPayload): Promise<Category> {
    const result = await http.post('/categories', category, { headers: authHeader() });
    return result.data;
  }

  async getAll(): Promise<Category[]> {
    const result = await http.get('/categories', { headers: authHeader() });
    return result.data;
  }

  async update(id: string, category: CreateCategoryPayload): Promise<void> {
    return http.put(`/categories/${id}`, category, { headers: authHeader() });
  }

  async updateMultiple(categories: Partial<Category>[]): Promise<void> {
    return http.put('/categories', categories, { headers: authHeader() });
  }

  async delete(id: string): Promise<void> {
    return http.delete(`/categories/${id}`, { headers: authHeader() });
  }
}

export const categoryService = new CategoryService();
